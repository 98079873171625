const NATIONAL_EXCESS = 'National_Excess';
const NATIONAL_NO_EXCESS = 'National_No_Excess';
const ROADSIDE_EXCESS = 'Roadside_Excess';
const ROADSIDE_NO_EXCESS = 'Roadside_No_Excess';

const EXCESS = 'Excess';
const NATIONAL_RECOVERY = 'National Recovery';

import {
  additionalOptionsArray,
  getAdditionalOptionJsonName,
  EUROPEAN_COVER
} from './optionNameUtils';
export const comparisonWebsiteSources = ['ct', 'ms', 'rc', 'go', 'mo', 'us'];

export const isValidComparisonWebsiteSource = (comparisonWebsiteSources: Array<string>, src: string): boolean => {
  return !comparisonWebsiteSources.includes(src.toLowerCase()) && process.env.NODE_ENV !== 'development' ? false : true;
};

const removeExcessFromProductName = (productName: string): string =>
  productName
    .replace('+ Excess', '')
    .replace('Excess +', '')
    .split(' + ')
    .map((p) => p.trim())
    .join(' + ');

export const getCoverPricingDataOnExcessChange = (
  hasExcess: string,
  productName: string,
  options,
  vehicleAge: number,
): { newProductName: string; coverPrice: number; baseOption: string } => {
  if (hasExcess === 'Yes') {
    const productNameLowerParts = productName.toLowerCase().split(' + ');
    if (productNameLowerParts.includes('excess') === false) {
      // Add + excess
      let newProductName = productName + ' + Excess';
      // add onward travel if we have national recovery and no onward travel
      if (productNameLowerParts.includes('national recovery') && !productNameLowerParts.includes('onward travel') ) {
        newProductName += ' + Onward Travel';
      }
      const { coverPrice, baseOption } = getCoverPricingData(options, newProductName, vehicleAge);
      return { newProductName, coverPrice, baseOption };
    } else {
      const { coverPrice, baseOption } = getCoverPricingData(options, productName, vehicleAge);
      return { newProductName: productName, coverPrice, baseOption };
    }
  } else {
    const newProductName = removeExcessFromProductName(productName);
    const { coverPrice, baseOption } = getCoverPricingData(options, newProductName, vehicleAge);
    return { newProductName, coverPrice, baseOption };
  }
};

const calculateTax = (amount: number, taxRatePct: number): number => (taxRatePct * amount) / 100;
const round = (num: number): number => Math.round(num * 100) / 100;
const calculateTotalCost = (vehicleCost: number, additionalOptionsCost: number): number =>
  round(vehicleCost + additionalOptionsCost);

const getAdditionalOptionsCost = (
  productNameArray,
  additionalOptions,
  taxRatePct: number,
  logging: boolean,
): number => {
  const additionalOptionsCost = additionalOptionsArray
    .map((option) => {
      const name = getAdditionalOptionJsonName(option);
      if (name === undefined) throw new Error(option + ' is undefined.');
      if (productNameArray.includes(option.toLowerCase())) {
        logging &&
          console.log(
            option + ' cost:',
            round(
              (additionalOptions[name].price_pence + calculateTax(additionalOptions[name].price_pence, taxRatePct)) /
                100,
            ),
          );
        return round(
          (additionalOptions[name].price_pence + calculateTax(additionalOptions[name].price_pence, taxRatePct)) / 100,
        );
      } else {
        return 0;
      }
    })
    .reduce((pre, cur) => pre + cur, 0);
  logging && console.log('additional options total:', round(additionalOptionsCost));
  return round(additionalOptionsCost);
};

const getVehicleCost = (vehicleAge: number, basePriceByAge, taxRatePct: number, logging: boolean): number => {
  const max = basePriceByAge[basePriceByAge.length - 1];
  const vehicleCost =
    vehicleAge > max.vehicle_age_yrs
      ? max.price_pence
      : basePriceByAge.find((v) => v.vehicle_age_yrs === vehicleAge).price_pence;
  logging && console.log('vehicle cost:', round(vehicleCost / 100));
  logging && console.log('vehicle cost no rounding:', vehicleCost);
  logging && console.log('vehicle cost tax no rounding:', calculateTax(vehicleCost, taxRatePct));
  logging &&
    console.log(
      'vehicle cost w/tax:',
      round((vehicleCost / 100) + (calculateTax(vehicleCost / 100, taxRatePct))),
    );
  return round((vehicleCost / 100) + (calculateTax(vehicleCost / 100, taxRatePct)));
};

export const getCoverPricingData = (
  // eslint-disable-next-line
  options,
  productName: string,
  vehicleAge: number,
  logging = false,
): { coverPrice: number; baseOption: string } => {
  logging && console.log("product name:", productName);
  const productNameArray = productName.toLowerCase().split(' + ');
  logging && console.log("product name array:", productNameArray);
  const taxRatePct = options[0].tax_rate_pct;

  if (taxRatePct === undefined) throw new Error('Tax rate percentage is undefined.');
  logging && console.log("Looking for:", NATIONAL_RECOVERY.toLowerCase());

  // National
  if (productNameArray.includes(EUROPEAN_COVER.toLowerCase()) || productNameArray.includes(NATIONAL_RECOVERY.toLowerCase())) {
    if (productNameArray.includes(EXCESS.toLowerCase())) {
      logging && console.log('national - includes excess');
      const nationalExcess = options.find((p) => p.option_id === NATIONAL_EXCESS);
      const vehicleCost = getVehicleCost(vehicleAge, nationalExcess.base_price_by_age, taxRatePct, logging);
      const additionalOptionsCost = getAdditionalOptionsCost(
        productNameArray,
        nationalExcess.additional_options,
        taxRatePct,
        logging,
      );
      logging && console.log('cover price:', calculateTotalCost(vehicleCost, additionalOptionsCost));
      return {
        coverPrice: calculateTotalCost(vehicleCost, additionalOptionsCost),
        baseOption: nationalExcess.option_id,
      };
    } else {
      logging && console.log('national - no excess');
      const nationalNoExcess = options.find((p) => p.option_id === NATIONAL_NO_EXCESS);
      const vehicleCost = getVehicleCost(vehicleAge, nationalNoExcess.base_price_by_age, taxRatePct, logging);
      const additionalOptionsCost = getAdditionalOptionsCost(
        productNameArray,
        nationalNoExcess.additional_options,
        taxRatePct,
        logging,
      );
      logging && console.log('cover price:', calculateTotalCost(vehicleCost, additionalOptionsCost));
      return {
        coverPrice: calculateTotalCost(vehicleCost, additionalOptionsCost),
        baseOption: nationalNoExcess.option_id,
      };
    }
    // Roadside
  } else {
    if (productNameArray.includes(EXCESS.toLowerCase())) {
      logging && console.log('roadside - includes excess');
      const roadsideExcess = options.find((p) => p.option_id === ROADSIDE_EXCESS);
      const vehicleCost = getVehicleCost(vehicleAge, roadsideExcess.base_price_by_age, taxRatePct, logging);
      const additionalOptionsCost = getAdditionalOptionsCost(
        productNameArray,
        roadsideExcess.additional_options,
        taxRatePct,
        logging,
      );
      logging && console.log('cover price:', calculateTotalCost(vehicleCost, additionalOptionsCost));
      return {
        coverPrice: calculateTotalCost(vehicleCost, additionalOptionsCost),
        baseOption: roadsideExcess.option_id,
      };
    } else {
      logging && console.log('roadside - no excess');
      const roadsideNoExcess = options.find((p) => p.option_id === ROADSIDE_NO_EXCESS);
      const vehicleCost = getVehicleCost(vehicleAge, roadsideNoExcess.base_price_by_age, taxRatePct, logging);
      const additionalOptionsCost = getAdditionalOptionsCost(
        productNameArray,
        roadsideNoExcess.additional_options,
        taxRatePct,
        logging,
      );
      logging && console.log('cover price:', calculateTotalCost(vehicleCost, additionalOptionsCost));
      return {
        coverPrice: calculateTotalCost(vehicleCost, additionalOptionsCost),
        baseOption: roadsideNoExcess.option_id,
      };
    }
  }
};
