import React, { useContext } from 'react';
import { Modal as MaterialUIModal, IconButton } from '@material-ui/core';
import { useStyles } from './RenewalModalStyles';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonPrimary, ButtonSecondary, RenewalRadioButtonGroup, Typography } from '../../atoms';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import renewalModalContentSchema from './RenewalModalSchema';
import { StepContext } from '../../../contexts/StepContext';

export interface RenewalIModal {
  open: boolean;
  setOpen: any;
  onClose?: () => void;
  heading?: string;
  headingClassName?: string;
  modalContainerClassName?: string;
  body: JSX.Element | string;
  componentType?: 'button' | 'icon' | 'fragment';
  buttonLabel?: string;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'generic';
  buttonDisabled?: boolean;
  buttonClassName?: string;
  disableBackdropClick?: boolean;
  name?: any;
  defaultValue?: string | undefined;
  watch?: any;
  control?: any;
  ariaLabel?: string | undefined;
}

interface IMuiModal {
  open: boolean;
  onClose: (() => void) | undefined;
  handleDefaultClose: (() => void) | undefined;
  disableBackdropClick: boolean | undefined;
}

const RadioButtonModal: React.FC<RenewalIModal> = ({
  open,
  setOpen,
  onClose,
  heading,
  headingClassName,
  modalContainerClassName,
  componentType,
  buttonLabel,
  buttonType,
  buttonDisabled,
  buttonClassName,
  disableBackdropClick,
  defaultValue,
  ariaLabel,
}) => {
  const classes = useStyles();
  const { radioBtnGroupData, renewalModalBodyContent } = renewalModalContentSchema;
  const { data } = useContext(StepContext);

  const { control, watch } = useForm();

  const handleOpen = () => setOpen(true);
  const handleDefaultClose = () => setOpen(false);

  const handleChange = (e) =>{
    data.automaticRenewal = e.target.value;
  };

  const getButton = (
    buttonDisabled: boolean,
    buttonType: 'primary' | 'secondary' | 'tertiary' | 'generic',
    buttonLabel: string,
    buttonClassName: string,
    handleOpen: () => void,
  ) => {
    switch (buttonType) {
      case 'primary':
        return (
          <ButtonPrimary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonPrimary>
        );
      case 'secondary':
        return (
          <ButtonSecondary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonSecondary>
        );
      default:
        return null;
    }
  };

  const content = (
    <>
      <div className={clsx(modalContainerClassName, classes.paper)}>
        <div className={classes.headingContainer}>
          <Typography variant="h4" className={headingClassName ? headingClassName : classes.headingText}>
            {heading}
          </Typography>
          <IconButton
            className={classes.iconButton}
            aria-label="Close"
            onClick={onClose !== undefined ? onClose : handleDefaultClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div>
          <Typography className={classes.bodyText}>{renewalModalBodyContent.body1}</Typography>
          <Typography className={classes.bodyText}>{renewalModalBodyContent.body2}</Typography>
          <Typography className={classes.bodyText}>{renewalModalBodyContent.body3}</Typography>
          <Typography className={classes.boldText}>{renewalModalBodyContent.body4}</Typography>
        </div>
        <RenewalRadioButtonGroup
          name="Automatic renewal radio button group"
          ariaLabel={ariaLabel}
          control={control}
          data={radioBtnGroupData}
          watch={watch}
          className={classes.flexOverride}
          onChange={handleChange}
          defaultValue={defaultValue}
        />
      </div>
    </>
  );

  const MuiModal = ({ open, onClose, handleDefaultClose }: IMuiModal) => {
    const classes = useStyles();
    return (
      <div className={modalContainerClassName}>
        <MaterialUIModal
          open={open}
          onClose={onClose !== undefined ? onClose : handleDefaultClose}
          className={classes.modal}
          disableBackdropClick={disableBackdropClick}
        >
          <div>{content}</div>
        </MaterialUIModal>
      </div>
    );
  };

  if (
    componentType === 'button' &&
    buttonDisabled !== undefined &&
    buttonType !== undefined &&
    buttonLabel !== undefined &&
    buttonClassName !== undefined
  ) {
    const button = getButton(buttonDisabled, buttonType, buttonLabel, buttonClassName, handleOpen);

    return (
      <div className={modalContainerClassName}>
        {button}
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  } else {
    return (
      <div className={modalContainerClassName}>
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  }
};

RadioButtonModal.defaultProps = {
  buttonType: 'primary',
  buttonLabel: '',
  buttonDisabled: false,
  buttonClassName: '',
  componentType: 'icon',
  disableBackdropClick: false,
};

export default RadioButtonModal;
