export const ModalSchema = {
    radioBtnGroupData: [
        {
            label: 'Yes',
            value: 'Yes',
        },
        {
            label: 'No',
            value: 'No',
        },
    ],
    renewalModalBodyContent: {
        body1: "You can choose to opt out of automatic renewal now.",
        body2: "If you do opt out, you'll have to contact us before your policy ends to stay covered.",
        body3: "If you're not sure now, you can always opt out of auto renewal at a later date.",
        body4: "Would you like to renew automatically?",
    },
};

export default ModalSchema;