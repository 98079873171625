import React from 'react';
import { Typography } from '../../components/atoms';

export const reviewSchema = {
  confirmationSideBarData1: {
    price: 478.29,
    date: '12/12/2022',
  },
  radioBtnGroupData: [
    {
      label: 'Renew automatically',
      value: 'Yes',
    },
    {
      label: 'No, thanks',
      value: 'No',
    },
  ],
  modalData: {
    heading: "Your renewal options.",
    body: "You can choose to opt out of automatic renewal now.",
    body2: "If you do opt out, you'll have to contact us before your policy ends to stay covered.",
    body3: "If you're not sure now, you can always opt out of auto renewal at a later date.",
  },
  automaticRenewalData: {
    heading: 'About automatic renewal',
    body: (
      <>
        <Typography>
          If you’re on auto renewal, we’ll securely store your payment details and use them when you renew. <br/>
          You can opt out online or over the phone at any time before your renewal date.
        </Typography>
        <Typography>
          If you’re not on auto renewal, your cover will stop when your policy ends. You’ll need to contact us to stay 
          covered for another year.
        </Typography>
      </>
    ),
  },
  rightToCancelData: {
    heading: 'Your right to cancel',
    body: (
      <>
        <Typography>You can cancel your policy at any time.</Typography>
        <Typography>
          If you cancel within 14 days of the policy starting or of you receiving the documents, whichever is later
          (this is the cooling off period), we’ll give you a full refund as long as you haven’t made a claim.
        </Typography>
        <Typography>
          If you cancel after that, as long as you haven’t made a claim, we’ll give you a refund for your premium based
          on how long your policy had left.
        </Typography>
      </>
    ),
  },
  makeComplaintData: {
    heading: 'Complaints',
    body: (
      <>
        <Typography>
          If you’re not happy about something, we want you to let us know. To find out how you can get in touch, visit{' '}
          <a href="https://www.greenflag.com/contact-us/complaints" target="_blank" rel="noreferrer">
            our complaints information page
          </a>{' '}
          for all the important information.
        </Typography>
        <Typography>
          You can also get in touch with the{' '}
          <a href="https://www.financial-ombudsman.org.uk/" target="_blank" rel="noreferrer">
            Financial Ombudsman Service (FOS)
          </a>{' '}
          if required.
        </Typography>
      </>
    ),
  },
  financialServices: {
    heading: 'Financial Services Compensation Scheme',
    body: (
      <>
        <Typography>
          General insurance claims are covered by the Financial Services Compensation Scheme. Full details of the cover available
          can be found{' '}<a href="https://www.fscs.org.uk/" target="_blank" rel="noreferrer">here</a>{' '}. U K Insurance 
          Limited is a member of this scheme.
        </Typography>
      </>
    ),
  },
  informationUsageData: {
    heading: "How we'll use your information",
    body: (
      <>
        <Typography>
          In short, we’ll use the information you’ve provided to get you a personalised price for your cover. All the
          details you’ve given, about yourself and anyone else on your policy, will help us get you the best price
          possible.
        </Typography>
        <Typography>
          If you break down, we’ll exchange your information with our network of garages and technicians. This helps us manage
          your policy and give you the best possible service.
        </Typography>
        <Typography>
          We’ll also work with credit reference agencies to check your credit status and identity. These agencies will
          note our enquiries, but your credit standing won’t be affected at all.
        </Typography>
        <Typography>
          Finally, we may check your details with fraud prevention agencies to prevent fraud and money laundering. If
          you provide false or inaccurate information, we’ll record this. For more details on how we’ll use your
          information,{' '}
          <a href="https://u-k-insurance.co.uk/brands-policy.html" target="_blank" rel="noreferrer">
            please read our privacy notice
          </a>
          .
        </Typography>
      </>
    ),
  },
  englishLawData: {
    heading: 'English law applies',
    body: (
      <>
        <Typography>
          You and we may choose which law will apply to this policy. Unless both parties agree otherwise English law
          will apply. We have supplied this policy and other information to you in English and we will continue to
          communicate with you in English.
        </Typography>
      </>
    ),
  },
};

export default reviewSchema;
