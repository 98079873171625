import React from 'react';
import { Typography, RadioButtonGroup } from '..';
import { useStyles } from './YourVoluntaryExcessStyles';
import yourVoluntaryExcessSchema from './YourVoluntaryExcessSchema.json';

interface IYourVoluntaryExcess {
  control: any;
  name: string;
  watch: any;
  defaultValue: string | undefined;
  handleRadioBtnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const YourVoluntaryExcess: React.FC<IYourVoluntaryExcess> = ({
  control,
  name,
  watch,
  defaultValue,
  handleRadioBtnChange
}: IYourVoluntaryExcess): JSX.Element => {
  const { yourVoluntaryExcessTypography, yourVoluntaryExcessRadioBtnGroup } = yourVoluntaryExcessSchema;
  const classes = useStyles();


  const typographySchema =
    watch(name) === 'Yes' ? yourVoluntaryExcessTypography.hasExcessYes : yourVoluntaryExcessTypography.hasExcessNo;

  return (
    <div className={classes.yourVoluntaryExcessContainer}>
      <Typography variant="h2" className="mb1">
        {typographySchema.heading}
      </Typography>
      {typographySchema.paragraphs.map((p: string, index: number) => (
        <Typography key={index} variant="body1" className="mb1">
          {p}
        </Typography>
      ))}

      <RadioButtonGroup
        ariaLabel="Your Voluntary Excess Radio Button Group"
        onChange={handleRadioBtnChange}
        control={control}
        name={name}
        data={yourVoluntaryExcessRadioBtnGroup}
        defaultValue={defaultValue}
        watch={watch}
      />
    </div>
  );
};

export default YourVoluntaryExcess;
