import React from 'react';
import { useStyles } from './YourCoverReceiptStyles';
import { Box } from '@material-ui/core';
import { Typography } from '..';
import { format, subDays, addYears } from 'date-fns';
import { convertDateToDlgFormat } from '../../../utils/dateFormattingUtils';
import clsx from 'clsx';

interface IYourCoverReceipt {
  startDate: Date | string | null | undefined;
}

export const YourCoverReceipt: React.FC<IYourCoverReceipt> = ({ startDate }: IYourCoverReceipt): JSX.Element => {
  const classes = useStyles();

  const formattedPolicyStartDate = startDate
    ? convertDateToDlgFormat(startDate.toString())
    : null;
  const policyStartDate = formattedPolicyStartDate ? format(new Date(formattedPolicyStartDate), 'do MMMM, yyyy') : '';
  const policyEndDate = formattedPolicyStartDate
    ? format(subDays(addYears(new Date(formattedPolicyStartDate), 1), 1), 'do MMMM, yyyy')
    : '';

  return (
    <>
      <Box className={classes.yourCoverReceiptContainer}>
        <Box className={classes.yourCoverReceiptInnerContainer}>
          <Typography className={clsx(classes.heading)} variant="h3">
            Your cover
          </Typography>

          <Box className={classes.policyDateContainer}>
            <Box className={classes.policyLabelContainer}>
              <Typography className={classes.policyLabel} variant="body2">
                Policy start date:
              </Typography>
            </Box>
            <Box>
              <Typography>
                <Box component="span">00:01am, </Box> {policyStartDate}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.policyDateContainer}>
            <Box className={classes.policyLabelContainer}>
              <Typography className={classes.policyLabel} variant="body2">
                Policy end date:
              </Typography>
            </Box>
            <Box>
              <Typography>
                <Box component="span">23:59pm, </Box>
                {policyEndDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default YourCoverReceipt;
