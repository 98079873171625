import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    yourVehiclesContainer: {
      marginTop: '1rem',
      '& div': {
        marginBottom: '0.5rem',
      },
      '& div:last-child': {
        marginBottom: '2rem',
      },
    },
    field: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
    fieldValue: {
      ...theme.form.labels?.selectFieldSmall.label,
    },
    vehicleDetails: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontWeight: 400,
    },
  }),
  { index: 1 },
);
