import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography } from '..';
import { useStyles } from './YourVehicleStyles';

interface IYourVehicle {
  registrationNumber: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleAge: number;
}

const YourVehicle: React.FC<IYourVehicle> = ({
  registrationNumber,
  vehicleMake,
  vehicleModel,
  vehicleAge,
}: IYourVehicle): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2">Your vehicle</Typography>
      <div className={classes.yourVehiclesContainer}>
        <Grid container>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Typography className={classes.field}>Registration</Typography>
            <Typography className={classes.field}>number:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={classes.fieldValue}>{registrationNumber}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} className="pt05">
            <Typography className={classes.field}>Make:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={classes.fieldValue}>{vehicleMake}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} className="pt05">
            <Typography className={classes.field}>Details:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={classes.fieldValue}>
              {vehicleMake} {vehicleModel}
            </Typography>
            <Typography className={classes.vehicleDetails}>{new Date().getFullYear() - +vehicleAge}</Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default YourVehicle;
