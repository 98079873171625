import React from 'react';
import { useStyles } from './NavStepperStyles';
import { Typography } from '../';
import { StepContext } from '../../../contexts/StepContext';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

export const NavStepper: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const context = React.useContext(StepContext);
  const { activeStep, steps } = context;

  return (
    <Box className={classes.root}>
      <Box className={classes.stepper}>
        <Box className={classes.stepperContainer}>
          {steps.map((step, index: number) => {
            const isActiveStep = activeStep === index + 1;
            if (step.hidden) return;
            return (
              <Box key={index} className={classes.stepContainer}>
                <Box
                  className={
                    isActiveStep ? clsx(classes.step, classes.circleActive) : clsx(classes.step, classes.circleInActive)
                  }
                >
                  <span>{index + 1}</span>
                </Box>
                <Typography className={isActiveStep ? classes.stepActive : ''} variant="h6" fontFamily="Oswald">
                  {step.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default NavStepper;
