import React, { useState, useEffect } from 'react';
import { useStyles } from './BillingAddressStyles';
import { InputLabel, Grid, Box } from '@material-ui/core';
import { LinkPrimary, TextField, Typography } from '..';
import { PostcodeLookup } from '../../molecules';

interface IBillingAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  postcode: string;
  plName: string;
  plPostcode: string;
  setValue: any;
  trigger: any;
  control: any;
}

export const BillingAddress: React.FC<IBillingAddress> = ({
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  addressLine5,
  postcode,
  plName,
  plPostcode,
  setValue,
  trigger,
  control,
}: IBillingAddress): JSX.Element => {
  const [showLookup, setShowLookup] = useState(false);
  const [lookupComplete, setLookupComplete] = useState(false);
  const classes = useStyles();

  const handleShowPostcodeLookup = () => {
    setValue('postcodeLookup.firstLineOfAddress', '');
    setValue('postcodeLookup.secondLineOfAddress', '');
    setValue('postcodeLookup.thirdLineOfAddress', '');
    setValue('postcodeLookup.town', '');
    setValue('postcodeLookup.county', '');
    setValue('postcodeLookup.postcode', '');
    setShowLookup(true);
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  useEffect(() => {
    if (lookupComplete === true && showLookup === true) {
      setShowLookup(false);
    }
  }, [lookupComplete]);

  if (showLookup) {
    return (
      <>
        <InputLabel className={classes.postcodeLabel} htmlFor="postcode">
          Postcode
        </InputLabel>
        <Box className={classes.lookupContainer}>
          <TextField
            placeholder="Postcode"
            id="postcode"
            name={plName}
            control={control}
            onKeyPress={handleOnKeyPress}
          />
          <PostcodeLookup
            setLookupComplete={setLookupComplete}
            className={classes.lookupBtn}
            buttonLabel="Find your address"
            buttonType="primary"
            name="postcodeLookup"
            setValue={setValue}
            postcode={plPostcode}
            trigger={trigger}
          />
        </Box>
      </>
    );
  }

  return (
    <Box className={classes.billingAddressContainer}>
      <Grid container className={classes.billingAddressGrid}>
        <Grid item xs={12}>
          <Typography variant="body1" className="fw-500">
            {addressLine1}
            {addressLine2 !== '' ? `, ${addressLine2} ` : ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {addressLine3 !== '' ? `${addressLine3}, ` : ''}
            {addressLine4 !== '' ? `${addressLine4}, ` : ''}
            {addressLine5 !== '' ? `${addressLine5}, ` : ''} {postcode}
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.bottomHighlight}>
        <LinkPrimary onClick={handleShowPostcodeLookup}>Not the right billing address?</LinkPrimary>
      </Box>
    </Box>
  );
};

export default BillingAddress;
