import React, { useEffect, useContext } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './CoverDetailsStyles';
import { Grid } from '@material-ui/core';
import { StepContext, IStepData, steps, Step } from '../../contexts/StepContext';
import {
  DocumentTitle,
  Typography,
  ButtonPrimary,
  YourDetails,
  YourVehicle,
  YourVoluntaryExcess,
  YourCover,
  Divider,
} from '../../components/atoms';
import { CoverSummary } from '../../components/molecules';
import {
  comparisonWebsiteSources,
  isValidComparisonWebsiteSource,
  getCoverPricingData,
  getCoverPricingDataOnExcessChange,
} from '../../utils/coverDetailsUtils';
import { buildInternalProductNameString } from '../../utils/optionNameUtils';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

interface IParams {
  id: string;
  hash:string;
}

export const CoverDetails: React.FC = () => {
  const { activeStep, updateActiveStep, loading, updateLoading, updateData, data } = useContext(StepContext);
  const { id, hash } = useParams<IParams>();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    updateActiveStep(1);
    // Set LivePerson Section
    if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Pcw', 'Cover Details Page'];

  }, []);

  const getQuoteData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_QUOTE_GET_ENDPOINT}/${id}/${hash}`
      );
      return response.data.data.Items[0];
    } catch (error: any) {
      if (error.response?.data?.message === 'Quote has expired') {
        history.replace('/timeout');
      }
    }
  };

  const getPricingData = async (quoteData) => {
    // Checks that the referring comparison site exists.
    if (!isValidComparisonWebsiteSource(comparisonWebsiteSources, quoteData.source))
      window.location.href = 'https://www.greenflag.com';

      try {
        const { data: options } = await axios.get(
          `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`
        );

        const productName = buildInternalProductNameString(quoteData.productName, quoteData.personalCover);
        const { coverPrice, baseOption } = getCoverPricingData(options.Items, productName, +quoteData.vehicleAge);

        const stateDataObjectInternal = {
          ...quoteData,
          productName,
          coverPrice,
          baseOption,
          hasVoluntaryExcess: productName.toLowerCase().split(' + ').includes('excess') ? 'Yes' : 'No',
          options: options.Items,
        };
        return stateDataObjectInternal;
      } catch (err) {
        window.location.replace("https://www.greenflag.com");
      };
  };

  const getData = async () => {
    if (id && (data.quote === '' || data.quote !== id)) {
      try {
        const quoteData = await getQuoteData();

        const quoteDataReactFormat = {
          source: quoteData.Source,
          title: quoteData.Title,
          firstName: quoteData.FirstName,
          lastName: quoteData.LastName,
          dateOfBirth: quoteData.DateOfBirth,
          emailAddress: quoteData.EmailAddress,
          phoneNumber: quoteData.PhoneNumber,
          postcode: quoteData.Postcode,
          addressLine1: quoteData.AddressLine1,
          addressLine2: quoteData.AddressLine2,
          addressLine3: quoteData.AddressLine3,
          addressLine4: '',
          vehicleType: quoteData.VehicleType,
          registrationNumber: quoteData.RegistrationNumber,
          vehicleMake: quoteData.VehicleMake,
          vehicleModel: quoteData.VehicleModel,
          vehicleAge: quoteData.VehicleAge,
          productName: quoteData.ProductName,
          personalCover: quoteData.PersonalCover,
          coverPrice: quoteData.CoverPrice,
          policyStartDate: quoteData.PolicyStartDate,
          partnerTitle: quoteData.PartnerTitle,
          partnerInitial: quoteData.PartnerInitial,
          partnerSurname: quoteData.PartnerSurname,
          quote: quoteData.quote_id,
          hash: hash,
        };
        const stateDataObject = await getPricingData(quoteDataReactFormat);
        updateData(stateDataObject);
        reset(stateDataObject);
        updateLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const schema = yup.object().shape({
    hasVoluntaryExcess: yup.string().required(),
  });

  const { 
    handleSubmit, 
    control, 
    formState, 
    watch, 
    reset, 
    setValue, 
    trigger 
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      hasVoluntaryExcess: data.productName.toLowerCase().split(' + ').includes('excess') ? 'Yes' : 'No',
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const coverPrice = watch('coverPrice');
  const productName = watch('productName');
  const hasVoluntaryExcess = watch('hasVoluntaryExcess');

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
    });
    history.push(steps[Step.REVIEW].url);
  };

  const handleRadioBtnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const pricingData = getCoverPricingDataOnExcessChange(e.target.value, productName, data.options, +data.vehicleAge);

    setValue('baseOption', pricingData.baseOption);
    setValue('productName', pricingData.newProductName);
    setValue('coverPrice', pricingData.coverPrice);
    trigger('baseOption');
    trigger('coverPrice');
    trigger('productName');

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // ensuring that the endpoint laods when we expect it to
  if (loading && data.quote === ''){
    getData();
  }

  if (loading) return <></>;

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Cover Details`} />
      <Controller control={control} name="coverType" defaultValue={data.coverType} render={() => <></>} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <CoverSummary
              firstName={data.firstName}
              coverPrice={coverPrice}
              policyStartDate={data.policyStartDate}
            />

            <Typography className={classes.detailsCorrectHeading} variant="h1">
              Please check that the details below are all correct.

            </Typography>
            <YourDetails
              firstName={data.firstName}
              lastName={data.lastName}
              dateOfBirth={data.dateOfBirth}
              addressLine1={data.addressLine1}
              addressLine2={data.addressLine2}
              addressLine3={data.addressLine3}
              addressLine4={data.addressLine4}
              addressLine5={data.addressLine5}
              postcode={data.postcode}
              partnerInitial={data.partnerInitial}
              partnerSurname={data.partnerSurname}
            />
            <YourVehicle
              registrationNumber={data.registrationNumber}
              vehicleMake={data.vehicleMake}
              vehicleModel={data.vehicleModel}
              vehicleAge={+data.vehicleAge}
            />
            <YourVoluntaryExcess
              control={control}
              name="hasVoluntaryExcess"
              watch={watch}
              defaultValue={hasVoluntaryExcess}
              handleRadioBtnChange={handleRadioBtnChange}
            />
            <YourCover personalCover={data.personalCover} productName={productName} />
            <Divider className={classes.mb3}></Divider>

            <Typography variant="h2" className={classes.lineHeight}>Everything looking good?</Typography>
            <Typography variant="h2" className={classes.lineHeight}>Then let&apos;s go make this official.</Typography>

            <div className={classes.actionButton}>
              <ButtonPrimary disabled={!formState.isValid} type="submit">
                Continue
              </ButtonPrimary>
            </div>
          </form>
        </Grid>
      </Grid>
    </PageTemplate> 
  );
};

export default CoverDetails;