import parse from "date-fns/parse";

const makeDateSeparatorsConsistent = (date: string): string => {
  const regex = /-/g;
  return date.replace(regex,'/');
};

export const getDateFormat = (date: string): string => {
  const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
  return dateWithExpectedSeparators.split('/')[2].length !== 4 ? 'yyyy/MM/dd' : 'dd/MM/yyyy';
};

export const convertDateToDlgFormat = (date: string) : Date => {
  const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
  return parse(dateWithExpectedSeparators, getDateFormat(dateWithExpectedSeparators), new Date());
};

export const convertDateToDisplayFormat = (date: string) : string => {
  const dateWithExpectedSeparators = makeDateSeparatorsConsistent(date);
  const dateObject = parse(dateWithExpectedSeparators, getDateFormat(dateWithExpectedSeparators), new Date());
  return dateObject.toLocaleDateString("en-GB");
};
