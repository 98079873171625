import React, { createContext, useReducer, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as actions from '../actions/StepActions';
import { DLG_DEBUG_STATE_EDITOR_ENABLED, DLG_DEBUG_STATE_EDITOR_DATA } from '../components/atoms/StateEditor';

export enum Step {
  COVER_OPTIONS = 0,
  REVIEW = 1,
  PAYMENT = 2,
}

interface ISteps {
  label: string;
  url: string;
  hidden?: boolean;
  showStepCountLabel?: boolean;
}

export const steps = [
  {
    label: 'Cover Details',
    url: '/coverOptions',
  },
  {
    label: 'Review',
    url: '/review',
  },
  {
    label: 'Payment',
    url: '/payment',
  },
  {
    label: 'All Sorted',
    url: '/all-sorted',
    hidden: true,
    showStepCountLabel: false
  },
    {
    label: 'Fake Comparison',
    url: '/fakecomparison',
    hidden: true,
    showStepCountLabel: false
  }
];

/* StateEditor component initialisation  */
const debugEnabled: string | null = localStorage.getItem(DLG_DEBUG_STATE_EDITOR_ENABLED);
const debugData = debugEnabled === 'true' ? localStorage.getItem(DLG_DEBUG_STATE_EDITOR_DATA) : null;

export const quoteData = {
  quote: '',
  source: '',
  title: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  emailAddress: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  addressLine5: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  billingAddressLine3: '',
  billingAddressLine4: '',
  billingAddressLine5: '',
  postcode: '',
  vehicleType: '',
  registrationNumber: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleAge: 0,
  productName: '',
  personalCover: undefined,
  baseOption: '',
  coverPrice: null,
  policyStartDate: undefined,
  partnerTitle: '',
  partnerInitial: '',
  partnerSurname: '',
  automaticRenewal: 'Yes',
  coverType: '',
  quoteTotal: 0,
  policyType: '',
  quoteId: null,
  hash: ''
};

export const paymentData = {
  cardholdersName: '',
  cardNumber: '',
  cardType: '',
  expiryDate: null,
};

export const summaryData = {
  paymentSuccessful: null,
};

export const initialNavStepperData = {
  ...quoteData,
  ...paymentData,
  ...summaryData,
  options: null,
};

export interface IBusinessDetailsAddress {
  firstLineOfAddress: string;
  secondLineOfAddress: string;
  town: string;
  county: string;
  postcode: string;
}

export interface IStepData {
  options: any;
  quote: string | number;
  source: string;
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string | Date;
  emailAddress: string;
  phoneNumber: number | string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingAddressLine3: string;
  billingAddressLine4: string;
  billingAddressLine5: string;
  postcode: string;
  vehicleType: string;
  registrationNumber: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleAge: number;
  productName: string;
  personalCover: string | undefined;
  coverPrice: null | number;
  policyStartDate: Date | string | null | undefined;
  partnerTitle: string;
  partnerInitial: string;
  partnerSurname: string;
  coverType: string;
  policyType: string;
  automaticRenewal: string;
  quoteId: null;
  cardholdersName: string;
  cardNumber: number | string;
  cardType: string;
  expiryDate: Date | null;
  quoteTotal: number;
  paymentSuccessful: boolean | null;
  baseOption: string;
  hash: string;
}

export interface IStep {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
}

const initialState = {
  activeStep: debugData ? JSON.parse(debugData).activeStep : 1,
  steps: steps,
  data: debugData ? JSON.parse(debugData).data : initialNavStepperData,
  loading: true,
  showStepper: true,
  updateLoading: () => {},
  updateQuotes: () => {},
  updateActiveStep: () => {},
  updateData: () => {},
  updateShowStepper: () => {},
};

export type StepType = {
  activeStep: number;
  steps: Array<ISteps>;
  data: IStepData;
  loading: boolean;
  showStepper: boolean;
  updateLoading: (isLoading: boolean) => void;
  updateQuotes: (quotes) => void;
  updateActiveStep: (newCount: number) => void;
  updateData: (newStepData: IStepData) => void;
  updateShowStepper: (showStepper: boolean) => void;
};

export const StepContext = createContext<StepType>(initialState);

const reducer = (state: IStep, action: actions.Action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: action.payload };
    case actions.UPDATE_QUOTES:
      return { ...state, data: { ...state.data, coverOptions: action.payload } };
    case actions.UPDATE_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case actions.UPDATE_DATA:
      return { ...state, data: { ...initialNavStepperData, ...action.payload } };
    case actions.UPDATE_SHOW_STEPPER:
      return { ...state, showStepper: action.payload };
    default:
      return state;
  }
};

export const StepProvider = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const sessionTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetSessionTimeout = () => {
    // preventing users from accessing pages without quote data
    if (!window.location.pathname.includes('fakecomparison') && !window.location.pathname.includes('coverOptions') && !window.location.pathname.includes('timeout') && !state.data?.quote) {
      resetData();
    }
    // preventing timeout loop on timeout page 
    if (!window.location.pathname.includes('fakecomparison') && !window.location.pathname.includes('timeout') && sessionTimeoutRef.current !== null) {
      clearTimeout(sessionTimeoutRef.current);
    }
    sessionTimeoutRef.current = setTimeout(resetData, 15 * 60 * 1000);
  };

  const resetData = () => {
    dispatch({ type: actions.LOADING, payload: true });
    dispatch({ type: actions.UPDATE_DATA, payload: { quoteData } });
    if (!state.data?.quote){
      history.replace('/timeout');
    }
  };

  useEffect(() => {
    resetSessionTimeout();

    return () => {
      if (sessionTimeoutRef.current !== null) {
        clearTimeout(sessionTimeoutRef.current);
      }
    };
  }, [state]);

  return (
    <StepContext.Provider
      value={{
        activeStep: state.activeStep,
        data: state.data,
        steps: state.steps,
        loading: state.loading,
        showStepper: state.showStepper,
        updateLoading: (isLoading: boolean) => dispatch({ type: actions.LOADING, payload: isLoading }),
        updateQuotes: (quotes) => dispatch({ type: actions.UPDATE_QUOTES, payload: quotes }),
        updateActiveStep: (newStep: number) => dispatch({ type: actions.UPDATE_ACTIVE_STEP, payload: newStep }),
        updateData: (newStepData: IStepData) => dispatch({ type: actions.UPDATE_DATA, payload: newStepData }),
        updateShowStepper: (showStepper: boolean) =>
          dispatch({ type: actions.UPDATE_SHOW_STEPPER, payload: showStepper }),
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export default StepProvider;
