import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  Typography,
  Notice,
  PolicyPayment,
  BillingAddress,
  Divider,
  TrustBox,
  LinkNavigation,
} from '../../components/atoms';
import { PaymentDetails } from '../../components/molecules';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './PaymentStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, format, startOfMonth, subDays } from 'date-fns';
import creditCardType from 'credit-card-type';
import * as yup from 'yup';
import paymentSchema from './PaymentSchema.json';
import axios from 'axios';
import clsx from 'clsx';
import { convertDateToDlgFormat } from '../../utils/dateFormattingUtils';
import { getPremiumType, getProviderID, isMobile } from '../../utils/paymentUtils';
import {buildAdditionalOptionsJsonNameArray} from '../../utils/optionNameUtils';

const MASTERCARD = 'MASTERCARD';
const MAESTRO = 'MAESTRO';
const VISA = 'VISA';
const AMERICAN_EXPRESS = 'AMEX';

const libraryToLocalCardTypeMapping = {
  "visa": VISA,
  "mastercard": MASTERCARD,
  "maestro": MAESTRO,
  "american-express": AMERICAN_EXPRESS
};

export const Payment: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data, updateShowStepper } = useContext(StepContext);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [cardinalInProgress, setCardinalInProgress] = useState(false);
  const minExpiryDate = startOfMonth(new Date());
  const classes = useStyles();
  const history = useHistory();
  

  const worldpayRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => worldpayRef.current?.scrollIntoView({behavior: 'smooth'});

  useEffect(() => {
    updateActiveStep(3);
    // Set LivePerson Section
    if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Pcw', 'Payment Page'];
  }, []);

  const getCardType = (): string | null => {
    const cct = creditCardType(getValues('cardNumber').toString());
    const libraryCardType = cct[0]?.type === undefined || cct.length > 1 ? null : cct[0].type;
    const localCardType = libraryCardType !== null && libraryCardType !== undefined ? libraryToLocalCardTypeMapping[libraryCardType] : null;
    return localCardType !== undefined ? localCardType : null;
  };

  const schema = yup.object().shape({
    cardholdersName: yup.string().required('Card holder name is required.'),
    cardNumber: yup
      .number()
      .typeError('Card number is required.')
      .required('Card number is required.')
      .when('cardType', {
        is: () => true,
        then: yup
          .number()
          .typeError('Card number is required.')
          .required('Card number is required.')
          .test('card-number', 'Invalid card number', (val: number | undefined, { createError }) => {
            const cardType = getCardType();
            if (cardType === MASTERCARD && val) {
              return new RegExp(
                '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)[0-9]{12,15}$',
              ).test(val?.toString());

            } else if (cardType === VISA && val) {
              return new RegExp('^4[0-9]{12,18}?$').test(val.toString());

            } else if (cardType === AMERICAN_EXPRESS && val) {
              return new RegExp('^3[47][0-9]{13}$').test(val?.toString());

            } else if (cardType === MAESTRO) {
              return createError({
                message: MAESTRO + ' is not supported.',
              });

            } else {
              return false;
            }
          }),
      }),
    cardSecurityNumber: yup
      .string()
      .typeError('Invalid card security number.')
      .required('Card security number is required.')
      .test('card-security-number', 'Invalid card security number', (val, context) => {
        const cardType = context.parent.cardType;
        const regex = /^[0-9]+$/;
        const lengthMap = {
          MASTERCARD: 3,
          VISA: 3,
          AMEX: 4
        };

        if (!cardType) {
          // card type is missing, dont raise this error
          return true;
        }
        return !cardType || (val?.length === lengthMap[cardType] && regex.test(val || ''));
      }),
    cardType: yup.string().required(),
    expiryDate: yup
      .date()
      .typeError('Invalid expiry date.')
      .required('Expiry date is required.')
      .min(minExpiryDate, 'Date cannot be before today.')
      .nullable(),
    postcodeLookup: yup.object().shape({
      firstLineOfAddress: yup.string().required('Please select an address.'),
      secondLineOfAddress: yup.string(),
      thirdLineOfAddress: yup.string(),
      town: yup.string().required('Please select an address.'),
      county: yup.string(),
      postcode: yup.string().required('Please select an address.'),
    }),
  });

  const {
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    trigger,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      cardSecurityNumber: '',
      worldpay3dSecureIframe: '',
      threeDSecure: false,
      paymentError: false,
      //IN accordance with legacy, we have line 1, line 2, line 3 (town) - use these, do not use the postcode lookup's third line of address
      // postcodeLookup: {
      //   firstLineOfAddress: data.addressLine1,
      //   secondLineOfAddress: data.addressLine2,
      //   thirdLineOfAddress: data.addressLine3,
      //   town: data.addressLine4,
      //   county: data.addressLine5,
      //   postcode: data.postcode,
      // },
      postcodeLookup: {
        firstLineOfAddress: data.addressLine1,
        secondLineOfAddress: data.addressLine2,
        //we only have two lines before the town in api data
        thirdLineOfAddress: '',
        town: data.addressLine3,
        county: data.addressLine5,
        postcode: data.postcode,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');
  const worldpay3dSecureIframe = watch('worldpay3dSecureIframe');
  const threeDSecure = watch('threeDSecure');

  const paymentSuccessful = () => {
    updateData({
      ...data,
      ...getValues(),
      billingAddressLine1: plAddressLine1,
      billingAddressLine2: plAddressLine2,
      billingAddressLine3: plAddressLine3,
      billingAddressLine4: plAddressLine4,
      billingAddressLine5: plAddressLine5,
      postcode: plPostcode,
      paymentSuccessful: true,
    });
    setPaymentInProgress(false);
    updateShowStepper(false);
    history.push('/all-sorted');
  };

  useEffect(() => {
    // Update cardType on cardNumber change.
    const cardType = getCardType();
    if (cardType === null) return;
    if (getValues('cardType') !== cardType) {
      setValue('cardType', cardType);
    }
  }, [watch('cardNumber')]);

  useEffect(() => {
    const handleIframeCallback = (event) => {
      let data: null | {
        boolStatus: boolean;
        stringStatus: string;
        orderId: string;
      } = null;

      if (event != null && event.data != null ) {
        try {data = JSON.parse(event.data);}
        catch {}
      } else {
        return;
      }
      if (!data?.stringStatus || !data?.orderId || data?.boolStatus == null || data?.boolStatus == undefined) {
      } else if (data?.boolStatus) {
        paymentSuccessful();
      } else {
        setValue('paymentError', true);
        setValue('worldpay3dSecureIframe', '');
        trigger('paymentError');
        setPaymentInProgress(false);
      }
    };

    if (threeDSecure === false) return;
    window.addEventListener('message', handleIframeCallback, true);
    return () => {
      window.removeEventListener('message', handleIframeCallback, false);
    };
  }, [threeDSecure]);

  const onSubmit = async () => {
    setPaymentInProgress(true);
    setValue('paymentError', false);
    trigger('paymentError');

    const generateJWT = () => {
      const jwtEndPoint =
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_JWT_ENDPOINT}?quoteId=` + data.quote;

      const Base64 = /[^A-Z0-9+\/=]/i;

      const getData = async (url) => {
        try {
          const resp = await axios.post(jwtEndPoint);
          if (resp.data.token != null && Base64.test(resp.data.token)) {
            return resp.data.token;
          }
        } catch (err) {
          setValue('paymentError', true);
          trigger('paymentError');
          setPaymentInProgress(false);
        }
      };
      return getData(jwtEndPoint);
    };

    const formattedPolicyStartDate = data.policyStartDate
      ? convertDateToDlgFormat(data.policyStartDate.toString())
      : '';
    const startDateForBackend = format(new Date(formattedPolicyStartDate), 'yyyy/MM/dd');

    const formattedDateOfBirth = data.dateOfBirth;
    const dobForBackend = format(new Date(formattedDateOfBirth), 'yyyy/MM/dd');

    generateJWT().then((jwtOutput) => {
      const innerHtml =
      "<body onload='document.collectionForm.submit();'>" +
      "<form id='collectionForm' name='collectionForm' method='POST' action=" + process.env.REACT_APP_DDC_ENDPOINT + ">" +
      "<input type='hidden' name='Bin' value='" + getValues('cardNumber').toString().substring(0, 6) + "'>" +
      "<input type='hidden' name='JWT' value='" +
      jwtOutput +
      "'>" +
      '</form>' +
      '</body>';

      //set iframe properties
      const iframe = document.createElement('iframe');
      iframe.id = 'ddcIframe';
      iframe.style.visibility = 'hidden';
      iframe.style.display = 'none';
      iframe.srcdoc = innerHtml;

      document.body.appendChild(iframe);

      const ddcTimeout = setTimeout(() => {
        setValue('paymentError', true);
        trigger('paymentError');
        setPaymentInProgress(false);
        setCardinalInProgress(false);
      }, 15000);

      const ddcEvent = async (event) => {
        if (cardinalInProgress === false) {
          setCardinalInProgress(true);
          if (event.origin === `${process.env.REACT_APP_DDC_ORIGIN}`) {
            window.removeEventListener('message', ddcEvent, false);
            const eventData = JSON.parse(event.data);
            console.warn('Merchant received a message:', eventData);
            if (eventData !== undefined && eventData.Status === true) {
              clearTimeout(ddcTimeout);
              const paymentObject = {
                Order: {
                  customer: {
                    title: data.title,
                    first_name: data.firstName,
                    last_name: data.lastName,
                    date_of_birth: dobForBackend,
                    address: {
                      house: data.addressLine1,
                      street: data.addressLine2,
                      town: data.addressLine3,
                      postcode: data.postcode,
                    },
                    telephone: data.phoneNumber,
                    email: data.emailAddress,
                  },
                  brand_no_contact: false,
                  partner: {
                    title: data.partnerTitle,
                    initial: data.partnerInitial,
                    surname: data.partnerSurname,
                  },
                  vehicle: {
                    registration: data.registrationNumber,
                    make: data.vehicleMake,
                    model: data.vehicleModel,
                    year_of_manufacture: new Date().getFullYear() - +data.vehicleAge,
                  },
                  continuous: data.automaticRenewal,
                  payment: {
                    card_type: getValues('cardType'),
                    card_number: getValues('cardNumber'),
                    card_holder_name: getValues('cardholdersName'),
                    card_expiry_date: format(new Date(getValues('expiryDate') || ''), 'MM/yy'),
                    cvc: getValues('cardSecurityNumber'),
                    billing_address:
                      `${plAddressLine1 !== '' ? `${plAddressLine1}` : ''}` +
                      `${
                        plAddressLine2 !== ''
                          ? plAddressLine1 !== ''
                            ? ` ${plAddressLine2}`
                            : `${plAddressLine2}`
                          : ''
                      }`,
                    billing_postcode: plPostcode,
                  },
                  start_date: startDateForBackend,
                  end_date: format(subDays(addYears(new Date(formattedPolicyStartDate), 1), 1), 'yyyy/MM/dd'),
                  quote_id: data.quote,
                  price_pence: data.coverPrice ? Math.round(data.coverPrice * 100) : 0,
                  vehicle_age_yrs: data.vehicleAge,
                  cover_options: {
                    base_option: data.baseOption,
                    additional_options: buildAdditionalOptionsJsonNameArray(data.productName.split(' + ')),
                  },
                  /* Comparison Site Analytics */
                  origin_site: data.source,
                  is_mobile: isMobile(),
                  product_name: data.productName,
                  // Probably these can be derived in the backend
                  Cancellation: 0,
                  Premium: data.coverPrice,
                  PremiumType: getPremiumType(data.productName),
                  ProviderId: getProviderID(data.source),
                  dfReferenceId: eventData.SessionId,
                },
              };

              try {
                const { data: paymentResponse } = await axios.post(
                  `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_ORDER_ENDPOINT}`,
                  paymentObject,
                );

                if (paymentResponse.stringStatus === 'AUTHORISED') {
                  paymentSuccessful();
                } else if (paymentResponse.stringStatus === '3DSECURE') {
                  setValue('worldpay3dSecureIframe', paymentResponse.iframe);
                  setValue('threeDSecure', true);
                  trigger('worldpay3dSecureIframe');
                  trigger('threeDSecure');
                  executeScroll();
                  // @ts-ignore
                  document.getElementById('challengeForm').submit();
                } else {
                  setValue('paymentError', true);
                  trigger('paymentError');
                  setPaymentInProgress(false);
                  setCardinalInProgress(false);
                }
              } catch (error) {
                setValue('paymentError', true);
                trigger('paymentError');
                setPaymentInProgress(false);
                setCardinalInProgress(false);
              }
            } else {
              setValue('paymentError', true);
              trigger('paymentError');
              setPaymentInProgress(false);
              setCardinalInProgress(false);
            }
          }
        }
      };
      window.addEventListener('message', ddcEvent, false);
    });
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    history.push(steps[Step.REVIEW].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Payment`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

            {!paymentInProgress && (
              <>
                <Typography variant="h1" className={classes.responsivePadding}>
                  Well, {data.firstName}, there&apos;s just one thing left to do.
                </Typography>

                <PolicyPayment price={data.coverPrice} />

                <Typography variant="h2" className="pt1 mb2">
                  Please enter your payment details
                </Typography>

                {getValues('paymentError') && (
                  <Notice
                    className="mb2"
                    heading={paymentSchema.notice.heading}
                    message={paymentSchema.notice.message}
                    messageType="error"
                  />
                )}

                <PaymentDetails
                  cardholdersName={data.cardholdersName}
                  cardNumber={data.cardNumber}
                  expiryDate={data.expiryDate}
                  cardSecurityNumber={''}
                  getCardType={getCardType}
                  control={control}
                  errors={errors}
                  AMERICAN_EXPRESS_TYPE_VALUE={AMERICAN_EXPRESS}
                />

                <Typography variant="h3" className="my2">
                  Billing address
                </Typography>

                <BillingAddress
                  addressLine1={plAddressLine1}
                  addressLine2={plAddressLine2}
                  addressLine3={plAddressLine3}
                  addressLine4={plAddressLine4}
                  addressLine5={plAddressLine5}
                  postcode={plPostcode}
                  plName="postcodeLookup.postcode"
                  plPostcode={plPostcode}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                />

                {errors.postcodeLookup && (
                  <Typography className="fieldError">
                    {errors.postcodeLookup?.firstLineOfAddress
                      ? errors.postcodeLookup?.firstLineOfAddress.message
                      : errors.postcodeLookup?.town
                      ? errors.postcodeLookup?.town.message
                      : errors.postcodeLookup?.postcode
                      ? errors.postcodeLookup.postcode.message
                      : ''}
                  </Typography>
                )}

                <Divider className="my3"></Divider>

                <Box className={clsx(classes.actionButton, 'mb2')}>
                  <ButtonPrimary disabled={paymentInProgress} loading={paymentInProgress} type="submit">
                    Buy your cover
                  </ButtonPrimary>
                </Box>
                <TrustBox />
              </>
            )}
          </form>

          <div ref={worldpayRef}>
            {worldpay3dSecureIframe && <div dangerouslySetInnerHTML={{__html: worldpay3dSecureIframe}}/> }
          </div>

        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Payment;
