import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      modal: {
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '777px',
        margin: 'auto',
        display: 'flex',

        '@media (max-height: 415px)': {
          display: 'block',
          height: '100%',
          overflow: 'scroll',
        },
      },
      paper: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(0, 4, 3),
      },
      headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.75rem',
      },
      headingText: {
        fontWeight: 500,
        fontSize: '1.5rem',
        flexDirection: 'row',
        paddingTop: '5rem',
        paddingBottom: '1.2rem',
      },
      bodyText: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '25px',
        paddingBottom: '2rem',
      },
      boldText: {
        fontSize: '20px',
        lineHeight: '25px',
        paddingBottom: '2rem',
        fontWeight: 'bold',
      },
      iconButton: {
        padding: 0,
        color: theme.palette.dark[50],
      },
      closeIcon: {
        float: 'right',
        fontSize: '2rem',
        marginLeft: 'auto',
        '&:hover': {
          cursor: 'pointer',
          color: theme.palette.error.main,
        },
      },
      helpIcon: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
      flexOverride: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
    }),
);
