import React from 'react';
import { useStyles } from './PolicyPaymentStyles';
import { Typography, Divider } from '..';

interface IPolicyPayment {
  price: number | null;
}

export const PolicyPayment: React.FC<IPolicyPayment> = ({ price }: IPolicyPayment): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.policyPaymentContainer}>
      <div className={classes.policyPaymentContainerBg}>
        <Typography variant="h3" className={classes.policyPaymentHeading}>
          Policy payment
        </Typography>
        <div className={classes.policyPaymentPrice}>
          <Typography variant="body1">Total cost for the year:</Typography>
          <Typography variant="body1" className={classes.price}>
            £{price ? price.toFixed(2) : ''}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.noticeContainer}>
          <Typography variant="body2" className={classes.notice}>
            Price includes Insurance Premium Tax.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PolicyPayment;
