import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    Heading: {
      marginTop: '4rem',
      marginBottom: '3rem',
      maxWidth: '35rem',
    },
    timeoutClock: {
      height: '30rem',
      width: '50vw',
      fill: 'none',
      verticalAlign: 'middle',
    },
    gridContainer: {
      position: 'relative',
    },
    gridMain: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2rem',
      },
    },
    gridImageContent: {
      textAlign: 'center',
      marginTop: '2rem',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    lineHeight: {
      lineHeight: '2rem',
    },
    mb3: {
      marginBottom: '3rem',
    },
  }),
  { index: 1 },
);