import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    actionButton: {
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
            minWidth: '18rem',
        },
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    responsivePadding: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
      '@media (min-width: 767px)': {
        marginTop: '3rem',
        marginBottom: '3rem',
      },
    }
  }),
  { index: 1 },
);
