import React, { useContext, useEffect } from 'react';
import { useStyles } from './AllSortedStyles';
import { DocumentTitle, Typography, YourCoverReceipt, NextSteps } from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { StepContext } from '../../contexts/StepContext';
import { Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const AllSorted: React.FC = () => {
  const { activeStep, data, updateShowStepper } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  if (process.env.NODE_ENV === 'development' ? false : activeStep !== 3 || data.paymentSuccessful !== true) {
    history.push('/');
    return <></>;
  }

  useEffect(() => {
    // Set LivePerson Section
    if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Pcw', 'All Sorted Page'];
    window.scrollTo(0, 0);
    updateShowStepper(false);
  }, []);

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - All Sorted`} />
      <Box className={classes.bgImage}>
        <Box className={classes.allSortedContainer}>
          <Typography className={classes.allSortedHeading} variant="h1">
            ALL SORTED!
          </Typography>
        </Box>

        <Grid className={classes.gridMainContainer}>
          <Grid item xs={12} lg={12} className={classes.gridMain}>
            <Box margin={'1rem 0'}>
              <Typography className={classes.welcome} variant="h1">
                Welcome to the Green Flag family, {data.firstName}.
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} margin={'0 1.2rem'}>
              <YourCoverReceipt startDate={data.policyStartDate} />
            </Box>
            <NextSteps />
            <Box className={classes.subHeadingSpacing}>
              <Typography className={classes.greenExitText}>
                RIGHT, THAT&apos;S ENOUGH FROM US. <br />
                GO ENJOY THE REST OF YOUR DAY!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageTemplate>
  );
};

export default AllSorted;
