import React, { useContext, useEffect, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonSecondary,
  ButtonPrimary,
  Typography,
  TextField,
  Accordion,
  Checkbox,
  Divider,
  LinkNavigation,
} from '../../components/atoms';
import { RenewalModal } from '../../components/molecules';
import { Grid, InputLabel, FormControl, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ReviewStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import GetAppIcon from '@material-ui/icons/GetApp';
import reviewSchema from './ReviewSchema';

export const Review: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(2);
    // Set LivePerson Section
    if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Pcw', 'Review Page'];
  }, []);

  const schema = yup.object().shape({
    emailAddress: yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
    phoneNumber: yup
      .string()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Please enter a valid mobile number'),
    vehicleCriteriaIsValid: yup.bool().oneOf([true], 'Checkbox must be checked to proceed.'),
    hasAutomaticRenewal: yup
      .string()
      .oneOf(['Yes', 'No'])
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      vehicleCriteriaIsValid: false,
      hasAutomaticRenewal: '',
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const hasAutomaticRenewal = watch('hasAutomaticRenewal');

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
    });
    history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.COVER_OPTIONS].url + '/' + data.quote + '/' + data.hash);
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  const handleShowRenewalModal = () => {
    setModalOpen(true);
  };

  const getDocument = (filePath: string) => window.open(filePath);

  const {
    modalData,
    automaticRenewalData,
    rightToCancelData,
    makeComplaintData,
    financialServices,
    informationUsageData,
    englishLawData,
  } = reviewSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

            <Typography className={clsx(classes.mainHeading, classes.responsivePadding)} variant="h1">
              We need a couple more details (and have some important info for you to check over).
            </Typography>
            <Typography variant="h2" className="mt3">
              Your contact details
            </Typography>
            <Typography className="pt1">
              We&apos;ll only use your email address and number if we need to get in touch about your policy.
            </Typography>

            <Box className="pt2">
              <InputLabel className="inputLabel" htmlFor="emailAddress">
                Email address
              </InputLabel>
              <TextField
                className={classes.emailAddressField}
                id="emailAddress"
                name="emailAddress"
                control={control}
                defaultValue={data.emailAddress}
                onKeyPress={handleOnKeyPress}
                error={errors.emailAddress ? true : false}
              />
              <Typography className="fieldError">{errors.emailAddress?.message}</Typography>
            </Box>

            <Box className="pt2">
              <InputLabel className="inputLabel" htmlFor="phoneNumber">
                Mobile number
              </InputLabel>
              <TextField
                className={classes.mobileNumberField}
                id="phoneNumber"
                name="phoneNumber"
                control={control}
                defaultValue={data.phoneNumber}
                onKeyPress={handleOnKeyPress}
                error={errors.phoneNumber ? true : false}
              />
              <Typography className="fieldError">{errors.phoneNumber?.message}</Typography>
            </Box>

            <Box className={clsx(classes.automaticRenewalContainer, 'pt2')}>
              <Typography variant="h2">Keeping you covered, 24/7</Typography>
              <Typography className="pt1">A few weeks before your policy ends, we&apos;ll send over your renewal quote.</Typography>
              <Typography className="pt1">
                If you&apos;re happy with your quote, you won&apos;t need to get in touch. <br/>
                You&apos;ll stay covered for another year.
              </Typography>
              <Typography className="pt1">
                And don&apos;t worry, you can still cancel or change your cover if you need to.
              </Typography>
              <Typography className="pt1">
                You can change your renewal preference at anytime during your policy. Or, to see your renewal options now, 
                <> </><a href="#" className={classes.tapHereAnchor} onClick={() => setModalOpen(true)}>tap here.</a>
              </Typography>

              {modalOpen === true && (
                <RenewalModal
                  name="hasAutomaticRenewal"
                  ariaLabel="Automatic renewal radio button group within modal"
                  open={modalOpen}
                  defaultValue={data.automaticRenewal}
                  setOpen={setModalOpen}
                  heading={modalData.heading}
                  body={modalData.body}
                  control={control}
                  watch={watch}
                />
              )}
            </Box>

            <Box className="pt3">
              <Typography className="my1" variant="h2">
                Important statements
              </Typography>
              <Typography className="mb1">
                Please make sure all the information you have told us is correct and your vehicle meets the criteria
                below. Your price is worked out using this information and if anything you’ve told us is wrong, you
                might not be covered. Your policy will run for 12 months from your cover start date.
              </Typography>
              <Typography className="mb1">Your vehicle(s) must meet these criteria:</Typography>
              <Box className={classes.importantStatementsList}>
                <ul>
                  <li className="mb05">
                    <Typography>
                      It&apos;s either a car, light van, motorhome or motorbike
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It&apos;s privately registered in the UK
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      There aren&apos;t more people in it than the manufacturer would recommend, or more than nine
                      altogether including the driver
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It can&apos;t weigh more than 3.5 metric tons (3,500kg) in total, including any load carried
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It can&apos;t be more than 7 metres long (apart from a tow bar or coupling device), 3 metres tall
                      and 2.55 metres wide
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It&apos;s been serviced, looked after and used as recommended by the manufacturer
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It&apos;s got a valid road tax and MOT certificate, if it needs one
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It must be fit to drive when you take out the policy and at the start of each journey
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      It&apos;s never used to carry things or people for money. For example, as a courier service or
                      taxi
                    </Typography>
                  </li>
                  <li className="mb05">
                    <Typography>
                      Personal Cover is only available for vehicles under 16 years old, that are privately registered and used in the UK.
                    </Typography>
                  </li>
                </ul>
                <div className="mb05">
                    <Typography>
                      Please see the{' '}
                      <a href="pdfs/PCW-Policy.pdf" target="_blank" rel="noreferrer">
                        policy wording
                      </a>{' '}
                      for more details on the types of vehicle we cover
                    </Typography>
                  </div>
              </Box>
            </Box>

            <Box className="pt1">
              <Accordion
                heading={automaticRenewalData.heading}
                body={automaticRenewalData.body}
                defaultExpanded={true}
              />
              <Accordion heading={rightToCancelData.heading} body={rightToCancelData.body} />
              <Accordion heading={makeComplaintData.heading} body={makeComplaintData.body} />
              <Accordion heading={financialServices.heading} body={financialServices.body} />
              <Accordion heading={informationUsageData.heading} body={informationUsageData.body} />
              <Accordion heading={englishLawData.heading} body={englishLawData.body} />
            </Box>

            <Box className="pt2">
              <Typography variant="h3" className="lead1">
                Insurance product information document (IPID)
              </Typography>
              <Typography className="py1">
                Here&apos;s a top-line summary of what your policy will and won&apos;t cover. Please note, it&apos;s not
                personalised to you.
              </Typography>
              <ButtonSecondary onClick={() => getDocument('pdfs/PCW-IPID.pdf')} endIcon={<GetAppIcon />}>
                Download PDF (202kb)
              </ButtonSecondary>
            </Box>

            <Box className="pt2">
              <Typography variant="h3" className="lead1">
                Breakdown cover policy booklet
              </Typography>
              <Typography className="py1">
                You can find out everything about our cover in this policy booklet.
              </Typography>
              <ButtonSecondary
                onClick={() => getDocument('pdfs/PCW-Policy.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (358kb)
              </ButtonSecondary>
            </Box>

            <Divider className="divider mt2"></Divider>

            <Box className="pt2">
              <Typography variant="h3" className="mb05">
                A very important checkbox
              </Typography>
              <FormControl component="fieldset">
                <Checkbox
                  name="vehicleCriteriaIsValid"
                  label="I confirm that my vehicle meets the criteria listed in the important statements above."
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  error={errors.vehicleCriteriaIsValid ? true : false}
                  color="primary"
                />
              </FormControl>
              <Typography className="fieldError">{errors.vehicleCriteriaIsValid?.message}</Typography>
            </Box>

            <Divider className="divider mt2"></Divider>

            <Typography variant="h2" className="mt2">
              Finally, let&apos;s continue to payment.
            </Typography>

            <Box className={classes.actionButton}>
              <ButtonPrimary type="submit">
                Continue
              </ButtonPrimary>
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Review;
