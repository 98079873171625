import { makeStyles, Theme } from '@material-ui/core/styles';
import LooneyBg from '../../pages/AllSorted/bgImage/Loony1.jpg';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    allSortedContainer: {
      textAlign: 'center',
    },
    allSortedHeading: {
      ...theme.fontFamilies.oswald,
      fontSize: '11.25rem', // 180px
      lineHeight: '11.255rem',
      color: theme.palette.light[50], 
      margin: '0 2rem',
      '@media (max-width: 893px)': {
        fontSize: '3.5rem',
        lineHeight: '3.5rem',
      },
      padding: '3rem',
      '@media (min-width: 767px)': {
        padding: '4rem',
      },
    },
    gridMainContainer: {
      ...theme.layouts.stepGrid.gridMainContainer,
      margin: '0 2rem 0 2rem',
      '@media (min-width: 965px)': {
        margin: 'auto',
      },
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      padding: '1rem',
      maxWidth: '900px',
      borderRadius: '4px',
    },
    welcome: {
      textAlign: 'center',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
    gridMain: {
      '@media (min-width: 767px)': {
        padding: '3rem',
      },
    },
    actionButton: {
      marginTop: '1rem',
      '& button': {
        minWidth: '17.5rem',
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    greenExitText: {
      fontSize: '2.625rem',
      lineHeight: '2.5rem',
      fontFamily: 'Oswald-Regular',
      color: theme.palette.green[800],
      '@media (max-width: 767px)': {
        fontSize: '2rem',
      },
    },
    subHeadingSpacing: {
      paddingBottom: '3rem',
      paddingTop: '4rem',
      '@media (min-width: 767px)': {
        paddingTop: '8rem',
        paddingLeft: '4rem',
      },
    },
    bgImage: {
      backgroundImage: `url(${LooneyBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      backgroundPosition: 'top',
    },
  }),
  { index: 1 },
);
