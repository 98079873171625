import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    noticeContainer: {
      padding: '1.4rem 1.5rem',
      maxWidth: '37.5rem',
    },
    default: {
      border: `2px solid ${theme.palette.light[400]}`,
      backgroundColor: theme.palette.light[50],
    },
    error: {
      border: `2px solid ${theme.palette.utility.redLight}`,
      backgroundColor: theme.palette.utility.noticeError,
    },
    heading: {
      ...theme.form.labels.inputField.label,
      marginBottom: '0.3rem',
    },
  }),
  { index: 1 },
);
