import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  yourCoverReceiptContainer: {
    marginBottom: '3rem',
    '& h3': {
      marginBottom: '2rem',
    },
  },
  yourCoverReceiptInnerContainer: {
    border: `2px solid ${theme.palette.light[400]}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    padding: '3rem',
    width: '100%',
    '@media (max-width: 765px)': {
      padding: '1.5rem',
    },
  },
  heading: {
    marginBottom: '2rem',
    '@media (max-width: 550px)': {
      marginBottom: '1rem',
      fontSize: '1.125rem',
    },
  },
  policyDateContainer: {
    display: 'flex',
    width: '100%',
    '& span': {
      marginBottom: '1rem',
    },
    '& p': {
      marginBottom: '1rem',
    },
    '@media (max-width: 550px)': {
      marginBottom: ' 1.2rem',
      flexDirection: 'column',
      '& span': {
        display: 'flex',
        marginBottom: 0,
      },
      '& p': {
        marginBottom: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  policyLabelContainer: {
    minWidth: '9rem',
  },
  policyLabel: {
    color: theme.palette.dark[600],
  },
}));
