import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    mainHeading: {
      '@media (max-width: 768px)': {
        ...theme.typography.h6,
      },
    },
    automaticRenewalContainer: {
      maxWidth: '30rem',
    },
    paragraphSpacing: {
      marginBottom: '0.75rem',
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    ul: {
      paddingLeft: '1.5rem',
      '& li': {
        fontSize: '1rem',
      },
    },
    checkbox: {
      '& span': {
        '& svg': {
          width: '36px',
          height: '36px',
        },
      },
    },
    emailAddressField: {
      maxWidth: '24.5rem',
      width: '100%',
    },

    tapHereAnchor: {
      fontStyle: 'normal',
      fontWeight: 700,
      textDecoration: 'underline',
      color: theme.palette.common.black,
    },

    mobileNumberField: {
      maxWidth: '24.5rem',
      width: '100%',
    },
    importantStatementsList: {
      '& a': {
        color: theme.palette.dark[800],
      },
    },
    actionButton: {
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
            minWidth: '18rem',
        },
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    responsivePadding: {
      marginTop: '1.5rem',
      '@media (min-width: 767px)': {
        marginTop: '3rem',
      },
    },
  }),
  { index: 1 },
);
