import React from 'react';
import { useStyles } from './NextStepsStyles';
import { Grid, Container } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CakeIcon from '@material-ui/icons/Cake';
import GooglePlay from '../../../assets/img/icons/google-play.png';
import Apple from '../../../assets/img/icons/Itunes.png';
import { Typography } from '../../atoms';


const NextSteps: React.FC = (): JSX.Element => {

  const classes = useStyles();

  return (
    <div>
        <Grid container>
            <Container>
                <Grid item xs={12}>
                    <Typography className={classes.yourNextStepsHeading} variant="h2">Your next steps</Typography>
                </Grid>
                <Grid item xs={12} className={classes.dFlex}>
                    <Grid item xs={1}>
                        <PhoneIphoneIcon className={classes.icons} />
                    </Grid>
                    <Grid item xs={10} className={classes.responsiveIconPadding}>
                        <Typography variant="h3" className={classes.headings}>Get the Green Flag app.</Typography>
                        <Typography className="pt1" variant="body2">
                            Download our app today. That way if you ever need us, we&apos;re just a few taps away.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={11}>{/* empty grid item */}</Grid> 
            </Container>
            <Container className="pb2">
                <div className={classes.dFlex}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} className={classes.badgeStyles}>
                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.directline.greenflag">
                            <img alt="Download our app from the Google play store" className={classes.iconMaxW} src={GooglePlay} />
                        </a>
                        <a className={classes.responsivePadding} target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/gb/app/green-flag/id986052194?mt=8">
                            <img alt="Download our app from the Itunes store" className={classes.iconMaxW} src={Apple} />
                        </a>
                    </Grid>
                </div>
            </Container>
            <Container className="pb2">
                <div className={classes.dFlex}>
                    <Grid item xs={1}>
                        <FindInPageIcon className={classes.icons} />
                    </Grid>
                    <Grid item xs={11} className={classes.responsiveIconPadding}>
                        <Typography variant="h3" className={classes.headings}>
                            Check over your policy documents.
                        </Typography>
                        <Typography className="pt1" variant="body2">
                            Your docs will be sent to you within 7-10 days. Once they&apos;ve arrived, make sure you read through them all carefully.
                        </Typography>
                    </Grid>
                </div>
            </Container>
            <Container>
                <div className={classes.dFlex}>
                    <Grid item xs={1}>
                        <Typography>
                            <CakeIcon className={classes.icons} />
                        </Typography>
                    </Grid>
                    <Grid item xs={11} className={classes.responsiveIconPadding}>
                        <Typography variant="h3" className={classes.headings}>
                            Celebrate.
                        </Typography>
                        <Typography className="pt1" variant="body2">
                            You&apos;re covered by Green Flag now. That&apos;s worth some cake and a couple of party poppers.
                        </Typography>
                    </Grid>
                </div>
            </Container>
        </Grid>
    </div>
  );
};

export default NextSteps;
