const HOMECALL = 'Home call';
const PERSONAL = 'Personal';
const HOMECALL_UPPER = 'HOME CALL';
const PERSONAL_UPPER = 'PERSONAL';
export const EUROPEAN_COVER = 'European Cover';
const EUROPEAN_UPPER = 'EUROPEAN COVER';
const ONWARD_TRAVEL = 'Onward Travel';
const ONWARD_UPPER = 'ONWARD TRAVEL';

const AGGREGATOR_EXCESS = 'Green Flag Excess';
const LOCAL_EXCESS = 'Excess';
const LOCAL_EXCESS_UPPER = 'EXCESS';

const NATIONAL = 'National Recovery';
const NATIONAL_UPPER = 'NATIONAL RECOVERY';
const ROADSIDE = 'Roadside Assistance';
const ROADSIDE_UPPER = 'ROADSIDE ASSISTANCE';
const PERSONAL_COVER_UPPER = 'PERSONAL COVER';
export const additionalOptionsArray = [HOMECALL, PERSONAL, ONWARD_TRAVEL, EUROPEAN_COVER];


export const getAdditionalOptionJsonName = (name: string): string | undefined => {
  switch (name.toUpperCase()) {
    case HOMECALL_UPPER:
      return 'Homecall';
    case PERSONAL_UPPER:
    case 'Personal Cover':
      return 'Personal';
    case ONWARD_UPPER:
    case 'Onward':
      return 'Onward';
    case EUROPEAN_UPPER:
      return 'European';
    default:
      undefined;
  }
};

export const buildAdditionalOptionsJsonNameArray = (productNameArray:string[]): (string|undefined)[] => {
  const jsonNameArray = productNameArray.map(getAdditionalOptionJsonName).filter(jsonName => jsonName != undefined);
  return jsonNameArray;
};

export const buildInternalProductNameString = (incomingProductName: string, personalCover: string) : string => {
  const productNameWithLocalExcess = incomingProductName.trim().replace(new RegExp(AGGREGATOR_EXCESS, "ig"), LOCAL_EXCESS);

  const productNameParts = productNameWithLocalExcess.split('+').map(part => part.trim());

  let needsNational = false;
  if( productNameParts.some(part => part.toUpperCase() === EUROPEAN_UPPER || part.toUpperCase() === ONWARD_UPPER)
   && productNameParts.every(part => part.toUpperCase() != NATIONAL_UPPER)) {
    needsNational = true;
  }
  let needsPersonal = false;
  if(personalCover?.toString().toLowerCase() === 'true' &&
      productNameParts.every(part => part.toUpperCase() != PERSONAL_UPPER && part.toUpperCase() != PERSONAL_COVER_UPPER)
    ) {
      needsPersonal = true;
  }
  let needsRoadside = false;
  if(productNameParts.every(part => part.toUpperCase() != ROADSIDE_UPPER)) {
    needsRoadside = true;
  }
  let needsOnward = false;
  if(productNameParts.some(part => part.toUpperCase() === EUROPEAN_UPPER || part.toUpperCase() === NATIONAL_UPPER ) &&
    productNameParts.some(part => part.toUpperCase() === LOCAL_EXCESS_UPPER) &&
    productNameParts.every(part => part.toUpperCase() != ONWARD_UPPER)){
      needsOnward = true;
  }

  if (needsNational) {
    productNameParts.push(NATIONAL);
  }

  if (needsRoadside) {
    productNameParts.push(ROADSIDE);
  }

  if (needsPersonal) {
    productNameParts.push(PERSONAL);
  }
  if (needsOnward) {
    productNameParts.push(ONWARD_TRAVEL);
  }

  const internalProductName = productNameParts.join(' + ');
  return internalProductName;
};

