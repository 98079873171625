import React, { useState } from 'react';
import { useStyles } from './YourCoverStyles';
import { Grid, IconButton } from '@material-ui/core';
import { Typography, Tooltip } from '..';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from '@material-ui/icons/Info';
import yourCoverSchema from './YourCoverSchema.json';
import clsx from 'clsx';

interface ICoverKeyPoints {
  title: string;
  content: string;
  productName:string;
}

interface IYourCover {
  personalCover: string | undefined,
  productName: string,
}

export const YourCover: React.FC<IYourCover> = ({
  personalCover,
  productName
}: IYourCover): JSX.Element => {
  const [tooltipOpenIndex, setTooltipOpenIndex] = useState<number | null>(null);
  const { coverKeyPoints } = yourCoverSchema;
  const classes = useStyles();

  const handleTooltipClose = () => {
    setTooltipOpenIndex(null);
  };

  const handleTooltipOpen = (index: number) => {
    setTooltipOpenIndex(index);
  };

  const getFilteredCoverKeyPoints = (productNames: Array<string>, personalCover: string|undefined, coverKeyPoints: Array<ICoverKeyPoints>,) => {

    const productNames_lower = productNames.map(item => item.toLowerCase());

    const filteredPersonalCover = personalCover === 'True' ? coverKeyPoints.filter((c) => c.title.toLowerCase() === 'personal cover') : [];
    const filteredCoverKeyPoints = coverKeyPoints.filter((c) => productNames_lower.includes(c.productName.toLowerCase()));

    return [...filteredPersonalCover, ...filteredCoverKeyPoints];
  };

  const filteredCoverKeyPoints = getFilteredCoverKeyPoints(productName.split(' + '), personalCover, coverKeyPoints);

  return (
    <div className={classes.yourCoverContainer}>

      <div className={classes.yourCoverBackground}>
      <Typography variant="h2" className="my2">
        Your cover includes:
      </Typography>
        <Grid container>
          {filteredCoverKeyPoints.map((k: ICoverKeyPoints, index: number) => (
            <Grid key={index} item xs={12} sm={6} className={classes.yourCoverGridItem}>
              <CheckIcon className={clsx(classes.checkIcon, index % 2 ? classes.secondGridColumn : '')} />
              <Typography className={classes.coverKeyPoint}>{k.title}</Typography>

              <Tooltip
                open={index === tooltipOpenIndex}
                onClose={handleTooltipClose}
                onClick={() => handleTooltipOpen(index)}
                title={<Typography variant="body2">{k.content}</Typography>}
                placement={index % 2 ? 'bottom-end' : 'bottom-start'}
                arrow
              >
                <IconButton className={classes.infoIconButton}>
                  {tooltipOpenIndex === index ? (
                    <InfoIcon className={classes.infoIconHighlight} />
                  ) : (
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  )}
                </IconButton>
              </Tooltip>

            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default YourCover;
